import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import './App.scss';
import bike from './images/bike.jpg';
import zombies from './images/zombie5.jpg';
import exclusive from './images/exclusive.jpg';
import tzScreens from './images/tzapp.jpg';
import tzscreenshots from './images/tzscreenshots.jpg';
import airz from './images/airz.jpg';
import svanholmSingers from './images/svanholmSingers.jpg';
import drommarnas from './images/drommarnas.jpg';


const IMAGES = [bike, tzScreens, zombies, exclusive, tzscreenshots, svanholmSingers, airz, drommarnas];

const Item = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

function Page({ url }) {
    return (
        <div
        className="App bg-text"
        style={{
            backgroundImage: `url(${url})`,
            fontSize: '10rem',
            backgroundAttachment: 'fixed',
        }}
      >
        <div
          className="faded-bg"
          style={{
            backgroundImage: `url(${url})`,
            backgroundAttachment: 'fixed',
          }}
        />
        <h1 className="centered">
            Johannes Hansen
        </h1>
      </div>
    );
}

class App extends Component {
  state = {
    current: 0,
  }

  componentDidMount() {
    this.timer = setInterval(this.nextSlide, 5000);
  }

  nextSlide = () => {
    this.setState({
      current: this.state.current < IMAGES.length - 1
        ? this.state.current + 1
        : 0
    });
  }

  render() {
    return (
     <div>
      <PoseGroup>
        <Item
            key={this.state.current}
        >
          <Page
            url={IMAGES[this.state.current]}
          />
        </Item>
      </PoseGroup>
     </div>
    );
  }
}

export default App;
